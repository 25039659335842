<div class="dialog" fxLayout="column">
    <h1 mat-dialog-title flex="100">Création d'une catégorie</h1>
    <div mat-dialog-content fxLayout="column">
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Titre*</mat-label>
            <input matInput type="text" [ngModel]="data.categorie.titre" (ngModelChange)="changeTitre($event)">
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Description*</mat-label>
            <textarea matInput type="text" [ngModel]="data.categorie.description" (ngModelChange)="changeDescription($event)"></textarea>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="50" fxLayoutAlign="center center">
            <button mat-raised-button color="warn" (click)="onNoClick()">Annuler</button>
          </div>
          <div fxFlex="50" fxLayoutAlign="center center">
            <button mat-raised-button color="primary" [mat-dialog-close]="data.categorie">Enregistrer</button>
          </div>
        </div>
    </div>
</div>