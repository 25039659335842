import { Component, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { Observable } from 'rxjs';
import { ConnectorService } from '../connector.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { CreateDoc, DocumentDto, DocumentStatus, DocumentTarget } from './document.dto';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
  fileList: DocumentDto[] = [];
  fileListObs: Observable<DocumentDto[]> = new Observable();

  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    public dialog: MatDialog,
    private router: Router
  ){}

  ngOnInit(): void {
    if(!this.connector.isConnected()){
      this.connector.disconnection();
    }
    this.getDocuments();
  }

  getURL(id:number){
    return 'https://my.sociochaux.fr/#/documents/'+id;
  }

  copyURL(id:number){
    navigator.clipboard.writeText(this.getURL(id));
  }
  
  async getDocuments(){
    const _this = this;
    this.fileListObs = await this.http.get<DocumentDto[]>(environment.backAPI+'/documents/admin',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    });
    this.fileListObs.subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          _this.fileList = result;
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }

  async delete(fileId:number){
    const _this = this;
    const doc:DocumentDto|undefined = await this.fileList.find(x => x.id == fileId);
    if(doc === undefined){
      return;
    }
    if(confirm("Êtes-vous sûr de vouloir supprimer le document '"+((doc.filename !== null)?doc.filename:doc.name)+"' ?")){
      await this.http.delete<DocumentDto[]>(environment.backAPI+'/documents/'+fileId,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ this.connector.token()
        }
      }).subscribe(
        (result) => {
          if(result !== undefined && !(result instanceof HttpErrorResponse)){
            alert("Document '"+((doc.filename !== null)?doc.filename:doc.name)+"' supprimé");
            _this.getDocuments();
          }
          else{
            this.connector.disconnection();
          }
        },
        (error) => {
          this.connector.disconnection();
        }
      );
    }
  }

  formatBytes(bytes:number,decimals: number) {
    if(bytes == 0) return '0 Bytes';
    var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  async fileDownload(id:number){
    const downloadName = (await this.fileList.find(x => x.id == id))?.filename;
    if(downloadName !== undefined){
      let anchor = document.createElement("a");
      document.body.appendChild(anchor);
      let file = environment.backAPI+'/documents/admin/'+id;
  
      let headers = new Headers();
      headers.append('Authorization', 'Bearer '+this.connector.token());
  
      fetch(file, { headers })
          .then(response => response.blob())
          .then(blobby => {
              let objectUrl = window.URL.createObjectURL(blobby);
  
              anchor.href = objectUrl;
              anchor.download = downloadName;
              anchor.click();
  
              window.URL.revokeObjectURL(objectUrl);
      });
    }else{
      alert("Ce document n'est pas trouvé dans la liste des documents disponibles")
    }
  }

  async upload(id:number, file:File){
    const _this = this;
    let formData:FormData = new FormData();
    formData.append('file', file)
    await this.http.post<boolean>(environment.backAPI+'/documents/upload/'+id, formData,{
      headers: {
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && result){
          alert("Document bien envoyé");
          this.getDocuments();
        }
        else{
          alert("Problème durant l'envoi document");
          this.connector.disconnection();
        }
      },
      (error) => {
        alert("Problème durant l'envoi document");
        this.connector.disconnection();
      }
    );
  }

  async openDialogDocument(docId?:number){
    const _this = this;
    const doc:DocumentDto|undefined = (docId !== undefined)?(await this.fileList.find(x => x.id == docId)):new DocumentDto();
    if(doc === undefined){
      alert("Un problème est survenu avec ce document");
      return ;
    }
    const docDialog:CreateDoc = new CreateDoc();
    docDialog.id = doc.id;
    docDialog.name = doc.name;
    docDialog.description = doc.description;
    docDialog.status = doc.status;
    docDialog.target = doc.target;

    const dialogRef = this.dialog.open(DocumentDialog, {
      data: {document: docDialog, parent: this},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined && result instanceof CreateDoc){
        let request;
        if(result.id > 0){
          request = this.http.post<Number>(environment.backAPI+'/documents', result, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+ this.connector.token()
            }
          });
        }else{
          request = this.http.put<Number>(environment.backAPI+'/documents', result, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+ this.connector.token()
            }
          });
        }
        request.subscribe(
          (result2) => {
            if(result2 !== undefined && Number.isInteger(result2)){
              if(result.file !== undefined){
                _this.upload(Number(result2), result.file);
              }else{
                this.getDocuments();
              }
            }
            else{
              this.connector.disconnection();
            }
          },
          (error) => {
            this.connector.disconnection();
          }
        );
      }
    });
  }
}

@Component({
  selector: 'doc-dialog',
  templateUrl: 'doc-dialog.html',
  styleUrls: ['./doc-dialog.css'],
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule, FormsModule, MatButtonModule, MatCardModule, MatIconModule, FlexLayoutModule, NgIf],
})
export class DocumentDialog {
  constructor(
    public dialogRef: MatDialogRef<DocumentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { document: CreateDoc, parent: DocumentsComponent},
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeName(value:string){
    this.data.document.name = value;
  }

  changeDescription(value:string){
    this.data.document.description = value;
  }

  changeStatus(value:string){
    if(value === 'draft'){
      this.data.document.status = DocumentStatus.DRAFT;
    }
    if(value === 'active'){
      this.data.document.status = DocumentStatus.ACTIVE;
    }
    if(value === 'inactive'){
      this.data.document.status = DocumentStatus.INACTIVE;
    }
  }

  changeTarget(value:string){
    if(value === 'all'){
      this.data.document.target = DocumentTarget.ALL;
    }
    if(value === 'socios'){
      this.data.document.target = DocumentTarget.SOCIOS;
    }
    if(value === 'adherents'){
      this.data.document.target = DocumentTarget.ADHERENTS;
    }
  }

  changeFile(event:any){
    this.data.document.file = event.target.files[0];
  }
}
