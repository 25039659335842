<div class="dialog" fxLayout="column">
    <h1 mat-dialog-title flex="100">Création d'une question</h1>
    <div mat-dialog-content fxLayout="column">
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Titre*</mat-label>
            <input matInput type="text" [ngModel]="data.question.titre" (ngModelChange)="changeTitre($event)">
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <angular-editor [ariaPlaceholder]="'Description'" [ngModel]="data.question.description" (ngModelChange)="changeDescription($event)"></angular-editor>
        </div>
        <div fxLayout="row">
          <div fxFlex="50" fxLayoutAlign="end center">Réponse obligatoire ?*&nbsp;</div>
          <div fxFlex="50">
            <mat-slide-toggle [ngModel]="data.question.obligatoire" (ngModelChange)="changeObligatoire($event)">
              <span *ngIf="data.question.obligatoire">Oui</span>
              <span *ngIf="!data.question.obligatoire">Non</span>
            </mat-slide-toggle>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="50" fxLayoutAlign="end center">Volontaires acceptés ?*&nbsp;</div>
          <div fxFlex="50">
            <mat-slide-toggle [ngModel]="data.question.volontariat" (ngModelChange)="changeVolontariat($event)">
              <span *ngIf="data.question.volontariat">Oui</span>
              <span *ngIf="!data.question.volontariat">Non</span>
            </mat-slide-toggle>
          </div>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Lien à afficher*</mat-label>
            <input matInput type="text" [ngModel]="data.question.lien" (ngModelChange)="changeLien($event)">
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="50" fxLayoutAlign="center center">
            <button mat-raised-button color="warn" (click)="onNoClick()">Annuler</button>
          </div>
          <div fxFlex="50" fxLayoutAlign="center center">
            <button mat-raised-button color="primary" [mat-dialog-close]="data.question">Enregistrer</button>
          </div>
        </div>
    </div>
</div>