import { Component, Inject } from '@angular/core';
import { CategorieDto, OptionPariDto, PariDto, PariStatus } from './paris.dto';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ConnectorService } from '../connector.service';
import { DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { environment } from '../../environments/environment';
import { NgIf } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-pronos',
  templateUrl: './pronos.component.html',
  styleUrls: ['./pronos.component.scss']
})
export class PronosComponent {
  listCategories: CategorieDto[] = [];
  toBeCreatedCat: CategorieDto = new CategorieDto();

  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    public dialog: MatDialog,
    private _adapter: DateAdapter<any>
  ) {}

  async ngOnInit() {
    if(!this.connector.isConnected()){
      this.connector.disconnection();
    }
    this._locale = 'fr';
    this._adapter.setLocale(this._locale);
    await this.getCategories();
  }

  async getCategories(){
    const _this = this;
    await this.http.get<CategorieDto[]>(environment.backAPI+'/paris/admin/categories',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          _this.listCategories = result;
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }

  switchAndSaveOption(categorie:CategorieDto, pari:PariDto, option:OptionPariDto){
    option.isValidated = !option.isValidated;
    this.saveOption(categorie, pari, option);
  }

  changeDateFin(pari:any, value:any){
    const myItem = this.checkHourFin(pari);
    value.setHours(myItem!.date_fin.getHours());
    value.setMinutes(myItem!.date_fin.getMinutes());
    myItem!.date_fin = value;
  }

  changeMinutesFin(pari:any, value:number){
    const myItem = this.checkHourFin(pari);
    myItem!.date_fin.setMinutes(value);
  }

  getMinutesFin(pari:any){
    const myItem = this.checkHourFin(pari);
    return myItem!.date_fin.getMinutes();
  }

  changeHourFin(pari:any, value:number){
    const myItem = this.checkHourFin(pari);
    myItem!.date_fin.setHours(value);
    myItem!.date_fin.setMinutes(myItem!.date_fin.getMinutes());
  }

  getHourFin(pari:any){
    const myItem = this.checkHourFin(pari);
    return myItem!.date_fin.getHours();
  }

  checkHourFin(myItem:any){
    if(!(myItem!.date_fin instanceof Date)){
      myItem!.date_fin = new Date(myItem!.date_fin);
    }
    return myItem;
  }

  changeStatus(pari:PariDto, value:string){
    let newValue:PariStatus = PariStatus.INACTIVE;
    if(value === PariStatus.ACTIVE.toString()){
      newValue = PariStatus.ACTIVE;
    }
    if(value === PariStatus.CANCELED.toString()){
      newValue = PariStatus.CANCELED;
    }
    if(value === PariStatus.ENDED.toString()){
      newValue = PariStatus.ENDED;
    }
    if(value === PariStatus.WAITING.toString()){
      newValue = PariStatus.WAITING;
    }
    pari.status = newValue;
  }

  changeStatusAndSave(pari:PariDto, value:string){
    this.changeStatus(pari, value);
    this.savePari(pari);
  }

  async getParis(categorieId:number){
    const _this = this;
    await this.http.get<PariDto[]>(environment.backAPI+'/paris/admin/bycategorie/'+categorieId,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          const categorie:CategorieDto = _this.listCategories.find(cat => cat.id === categorieId)!;
          categorie.paris = result;
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }

  nextStatusAndSavePari(pari:PariDto){
    if(pari.status){
      if(pari.status === PariStatus.INACTIVE){
        pari.status = PariStatus.ACTIVE;
      }
      if(pari.status === PariStatus.WAITING){
        pari.status = PariStatus.ENDED;
      }
    }
    this.savePari(pari);
  }

  async savePari(pari:PariDto){
    this.http.post<PariDto>(environment.backAPI+'/paris/admin/pari', pari, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          this.getParis(pari.categorie.id);
        }
        else{
          this.connector.disconnection();
        }
      },
      (error) => {
        this.connector.disconnection();
      }
    );
  }

  async getOptions(categorieId:number, pariId:string){
    const _this = this;
    await this.http.get<OptionPariDto[]>(environment.backAPI+'/paris/admin/byparis/'+pariId,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          const categorie:CategorieDto = _this.listCategories.find(cat => cat.id === categorieId)!;
          const pari:PariDto = categorie.paris.find(par => par.id === pariId)!;
          pari.options = result;
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }

  orderOptions(){
    for(const cat of this.listCategories){
      for(let paris of cat.paris){
        paris.options = paris.options.sort((n1,n2) => {
          if (n1 > n2) {
            return 1;
          }
      
          if (n1 < n2) {
              return -1;
          }
      
          return 0;
        });
      }
    }
  }

  async saveOption(categorie:CategorieDto, pari:PariDto, option:OptionPariDto){
    this.http.post<OptionPariDto>(environment.backAPI+'/paris/admin/option', option, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          this.getOptions(categorie.id, pari.id);
        }
        else{
          this.connector.disconnection();
        }
      },
      (error) => {
        this.connector.disconnection();
      }
    );
  }
  
  getById(id:number):CategorieDto|undefined{
    return this.listCategories.find(c => c.id == id);
  }

  changeValue<T>(myObject:T, field: string, value:any){
    type ObjectKey = keyof typeof myObject;
    const myVar = field as ObjectKey;
    myObject[myVar] = value;
  }

  async saveCategorie(cat:CategorieDto){
    this.http.post<CategorieDto>(environment.backAPI+'/paris/admin/categorie', cat, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          this.getCategories();
        }
        else{
          this.connector.disconnection();
        }
      },
      (error) => {
        this.connector.disconnection();
      }
    );
  }

  openCategorieDialog(){
    const dialogRef = this.dialog.open(CategorieDialog, {
      data: {categorie: this.toBeCreatedCat, parent: this},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined && result instanceof CategorieDto){
        this.http.put<CategorieDto>(environment.backAPI+'/paris/admin/categorie', result, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ this.connector.token()
          }
        }).subscribe(
          (result) => {
            if(result !== undefined && !(result instanceof HttpErrorResponse)){
              this.getCategories();
            }
            else{
              this.connector.disconnection();
            }
          },
          (error) => {
            this.connector.disconnection();
          }
        );
      }
    });
  }

  openDialogParis(id:number){
    const dialogRef = this.dialog.open(PariDialog, {
      data: {pari: new PariDto(), parent: this},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined && result instanceof PariDto){
        this.http.post<CategorieDto>(environment.backAPI+'/paris/admin/pari/cat/'+id, result, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ this.connector.token()
          }
        }).subscribe(
          (result) => {
            if(result !== undefined && !(result instanceof HttpErrorResponse)){
              this.getParis(id);
            }
            else{
              this.connector.disconnection();
            }
          },
          (error) => {
            this.connector.disconnection();
          }
        );
      }
    });
  }

  openDialogJournee(id:number){
    const dialogRef = this.dialog.open(JourDialog, {
      data: {pari: { titre: "J", date_fin: new Date(), team: "", isHome: false }, parent: this},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.http.post<Boolean>(environment.backAPI+'/paris/admin/pari/cat/multi/'+id, result, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ this.connector.token()
          }
        }).subscribe(
          (result) => {
            if(result !== undefined && !(result instanceof HttpErrorResponse)){
              this.getParis(id);
            }
            else{
              this.connector.disconnection();
            }
          },
          (error) => {
            this.connector.disconnection();
          }
        );
      }
    });
  }

  openDialogOption(categorieId:number, pari:PariDto){
    const dialogRef = this.dialog.open(OptionDialog, {
      data: {option: new OptionPariDto(), parent: this},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined && result instanceof OptionPariDto){
        this.http.post<CategorieDto>(environment.backAPI+'/paris/admin/option/pari/'+pari.id, result, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ this.connector.token()
          }
        }).subscribe(
          (result) => {
            if(result !== undefined && !(result instanceof HttpErrorResponse)){
              this.getOptions(categorieId, pari.id);
            }
            else{
              this.connector.disconnection();
            }
          },
          (error) => {
            this.connector.disconnection();
          }
        );
      }
    });
  }
}

@Component({
  selector: 'categorie-dialog',
  templateUrl: 'dialog-categorie/categorie-dialog.html',
  styleUrls: ['./dialog-categorie/categorie-dialog.css'],
  standalone: true,
  imports: [
    MatSlideToggleModule,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    NgIf
  ],
})
export class CategorieDialog {
  constructor(
    public dialogRef: MatDialogRef<CategorieDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { categorie: CategorieDto, parent: PronosComponent },
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  changeTitre(value:string){
    this.data.categorie.titre = value;
  }
  
  changeDescription(value:string){
    this.data.categorie.description = value;
  }
}

@Component({
  selector: 'pari-dialog',
  templateUrl: 'dialog-pari/pari-dialog.html',
  styleUrls: ['./dialog-pari/pari-dialog.css'],
  standalone: true,
  imports: [
    MatSlideToggleModule,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    NgIf
  ],
})
export class PariDialog {
  constructor(
    public dialogRef: MatDialogRef<PariDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { pari: PariDto, parent: PronosComponent },
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'jour-dialog',
  templateUrl: 'dialog-jour/jour-dialog.html',
  styleUrls: ['./dialog-jour/jour-dialog.css'],
  standalone: true,
  imports: [
    MatSlideToggleModule,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    NgIf
  ],
})
export class JourDialog {
  constructor(
    public dialogRef: MatDialogRef<JourDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { pari: { titre: string, date_fin: Date, team: string, isHome: boolean }, parent: PronosComponent },
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'option-dialog',
  templateUrl: 'dialog-option/option-dialog.html',
  styleUrls: ['./dialog-option/option-dialog.css'],
  standalone: true,
  imports: [
    MatSlideToggleModule,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    NgIf
  ],
})
export class OptionDialog {
  constructor(
    public dialogRef: MatDialogRef<OptionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { option: OptionPariDto, parent: PronosComponent },
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}