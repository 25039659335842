<div class="dialog" fxLayout="column">
    <h1 mat-dialog-title flex="100">Création d'une consultation</h1>
    <div mat-dialog-content fxLayout="column">
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Titre*</mat-label>
            <input matInput type="text" [ngModel]="data.cons.titre" (ngModelChange)="changeTitre($event)">
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <angular-editor fxFlex="100" [ariaPlaceholder]="'Description'" [ngModel]="data.cons.description" (ngModelChange)="changeDescription($event)"></angular-editor>
        </div>
  
        <div flex="" fxLayout="row" fxLayoutAlign="left">
          <mat-form-field fxFlex="100">
              <mat-label>Date de début*</mat-label>
              <input matInput [matDatepicker]="pickerDebut" name="date_debut" [ngModel]="data.cons.date_debut">
              <mat-hint>JJ/MM/AAAA</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="pickerDebut"></mat-datepicker-toggle>
              <mat-datepicker #pickerDebut></mat-datepicker>
          </mat-form-field>
        </div>
        
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Heure de début*</mat-label>
            <input matInput type="number" min="1" max="23" [ngModel]="getHourDebut()" (ngModelChange)="changeHourDebut($event)">
          </mat-form-field>
        </div>
  
        <div flex="" fxLayout="row" fxLayoutAlign="left">
          <mat-form-field fxFlex="100">
              <mat-label>Date de fin*</mat-label>
              <input matInput [matDatepicker]="pickerFin" name="date_fin" [ngModel]="data.cons.date_fin" (ngModelChange)="changeDateFin($event)">
              <mat-hint>JJ/MM/AAAA</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="pickerFin"></mat-datepicker-toggle>
              <mat-datepicker #pickerFin></mat-datepicker>
          </mat-form-field>
        </div>
        
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Heure de fin*</mat-label>
            <input matInput type="number" min="1" max="23" [ngModel]="getHourFin()" (ngModelChange)="changeHourFin($event)">
          </mat-form-field>
        </div>
  
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
              <mat-label>Public cible*</mat-label>
              <mat-select [ngModel]="data.cons.target" (ngModelChange)="changeTarget($event)">
                <mat-option value="all">Tous</mat-option>
                <mat-option value="socios">Socios seulement</mat-option>
                <mat-option value="adherents">Adhérents seulement</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
  
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
              <mat-label>Type de consultation*</mat-label>
              <mat-select [ngModel]="data.cons.type" name="type" (ngModelChange)="changeType($event)">
                <mat-option value="credits">Crédits <mat-icon>toll</mat-icon></mat-option>
                <mat-option value="valeurs">Oui/Non <mat-icon>rule</mat-icon></mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" *ngIf="data.cons.type === 'credits'">
          <mat-form-field fxFlex="100">
            <mat-label>Nombre total de crédits*</mat-label>
            <input matInput type="number" min="1" [ngModel]="data.cons.credits" (ngModelChange)="changeCredits($event)">
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="50" fxLayoutAlign="center center">
            <button mat-raised-button color="warn" (click)="onNoClick()">Annuler</button>
          </div>
          <div fxFlex="50" fxLayoutAlign="center center">
            <button mat-raised-button color="primary" [mat-dialog-close]="data.cons">Enregistrer</button>
          </div>
        </div>
    </div>
</div>