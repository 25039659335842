<div fxLayout="column" fxFlex="100">
    <h2 fxLayout="row" fxFlex="100" fxLayoutAlign="center center">Documents</h2>
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
      <button mat-fab color="primary" aria-label="Créer un document" (click)="openDialogDocument()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">&nbsp;</div>
    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
        <mat-card *ngFor="let file of fileList" fxLayout="column" fxFlex="100" style="margin-top:10px;">
            <mat-card-title fxFlex="100" fxLayoutAlign="center center">
                {{ file.name }}<span *ngIf="file.size !== null">&nbsp;- {{ formatBytes(file.size,2) }}</span>
                <span class="spacer"></span>
                <button mat-icon-button color="primary" aria-label="Supprimer un document" (click)="openDialogDocument(file.id)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" aria-label="Supprimer un document" (click)="delete(file.id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-card-title>
            <mat-card-content fxFlex="100">
                <mat-divider></mat-divider>
                <p><strong>Statut:</strong>&nbsp;
                    <span *ngIf="file.status === 'draft'" style="color: grey">Brouillon</span>
                    <span *ngIf="file.status === 'active'" style="color: green">Publié</span>
                    <span *ngIf="file.status === 'inactive'" style="color: orange">Désactivé</span>
                    &nbsp;|&nbsp;
                    <strong>&Eacute;dition:</strong>&nbsp;{{ file.update | date:'dd/MM/yyyy' }}
                </p>
                <p><strong>Description:</strong> {{ file.description }}</p>
                
                <mat-form-field style="width: 100%" *ngIf="file.status === 'active'">
                    <mat-label>URL publique</mat-label>
                    <input matInput type="text" [value]="getURL(file.id)">
                    <button matSuffix mat-icon-button aria-label="Rechercher" (click)="copyURL(file.id)">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions fxFlex="100" fxLayoutAlign="center center">
                <button mat-raised-button *ngIf="file.type !== null" (click)="fileDownload(file.id)">{{ file.filename }}</button> <button mat-raised-button disabled *ngIf="file.type === null">Aucun fichier chargé</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>