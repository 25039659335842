<div fxLayout="column" fxFlex="100">
    <h1 fxLayout="row" fxFlex="100" fxLayoutAlign="center center">Statistiques</h1>
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
        <mat-form-field fxFlex="100">
            <mat-label>Consultations</mat-label>
            <select matNativeControl required (change)="changeConsultation($event)">
              <option value="0"></option>
              <option *ngFor="let consultation of listConsultations" [value]="consultation.id">{{consultation.titre}} ({{getStatus(consultation.status)}})</option>
            </select>
        </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" *ngIf="openedStat.length > 0 && openedStat[0].type==='credits'">
        <h1 flex="" fxLayout="row" fxLayoutAlign="center" *ngIf="isRGPD() && consHasVolontaires()">
            <button mat-raised-button color="primary" (click)="fileDownload(currentConsultation)">Télécharger les volontaires</button>
        </h1>
        <mat-card *ngFor="let stat of openedStat; let index = index" fxFlex="100" fxLayout="column" style="padding:5px;margin-bottom: 10px; width: 100%">
            <mat-card-title fxFlex="100"><strong>{{ index+1 }}. Question : </strong>{{ stat.titre }}</mat-card-title>
            <mat-card-content fxFlex="100">
                <p>
                    <strong>Total points : </strong>{{ stat.somme }} ({{getPourcentage(stat.somme)}}%)&nbsp;|&nbsp;<strong>Nombre votants : </strong>{{ stat.quantite }}&nbsp;|&nbsp;<strong>Moyenne pts/votant : </strong>{{ getAverage(stat) }}
                </p>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" *ngIf="openedStat.length > 0 && openedStat[0].type==='valeurs'">
        <h1 flex="" fxLayout="row" fxLayoutAlign="center" *ngIf="isRGPD() && consHasVolontaires()">
            <button mat-raised-button color="primary" (click)="fileDownload(currentConsultation)">Télécharger les volontaires</button>
        </h1>
        <mat-card *ngFor="let stat of openedStat; let index = index" fxFlex="100" fxLayout="column" style="padding:5px;margin-bottom: 10px; width: 100%">
            <mat-card-title fxFlex="100"><strong>{{ index+1 }}. Question : </strong>{{ stat.titre }}</mat-card-title>
            <mat-card-content fxFlex="100">
                <p>
                    <strong>Total votants: </strong>{{ stat.total }}&nbsp;|&nbsp;<strong>Oui: </strong>{{ stat.oui }} ({{getPourcentageValeurs(stat.oui, stat.total)}}%)&nbsp;|&nbsp;<strong>Non: </strong>{{ stat.non }} ({{getPourcentageValeurs(stat.non, stat.total)}}%)&nbsp;|&nbsp;<strong>Abs: </strong>{{ stat.abs }} ({{getPourcentageValeurs(stat.abs, stat.total)}}%)
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</div>