export enum PariStatus {
    INACTIVE = "inactive",
    ACTIVE = "active",
    WAITING = "waiting",
    ENDED = "ended",
    CANCELED = "canceled",
}

export class CategorieDto{
    id:number = 0;
    titre: string = "";
    description: string = "";
    paris: PariDto[] = [];
}

export class PariDto {
    id:string = "";
    titre: string = "";
    description: string = "";
    status: PariStatus = PariStatus.CANCELED;
    date_fin: Date = new Date();
    categorie: CategorieDto = new CategorieDto();
    options: OptionPariDto[] = [];
}

export class OptionPariDto{
    id:string = "";
    titre: string = "";
    description: string = "";
    score: number = 0;
    isValidated: boolean = false;
    pari: PariDto = new PariDto();
}

export class PronosticDto{
    id: string = "";
    date: Date = new Date();
    isLocked: boolean = false;
    option: OptionPariDto = new OptionPariDto();
}
