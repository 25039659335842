<div fxLayout="column">
    <h1 fxLayout="row" fxFlex="100" fxLayoutAlign="center center">Pronostics</h1>
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
      <button mat-raised-button color="primary" aria-label="Créer une consultation" (click)="openCategorieDialog()">
        <mat-icon>add</mat-icon>Créer une catégorie
      </button>
    </div>
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">&nbsp;</div>
    <mat-accordion class="example-headers-align" multi fxLayout="column" fxFlex="100">
      <mat-expansion-panel *ngFor="let categorie of listCategories" fxLayout="column" fxFlex="100">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ categorie.titre }}
          </mat-panel-title>
          <mat-panel-description>
            ID {{ categorie.id }}&nbsp;|&nbsp;
            {{ categorie.description }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="100" fxLayoutAlign="center center">
            <mat-accordion class="example-headers-align" multi fxLayout="column" fxFlex="100">
              <mat-expansion-panel fxLayout="column" fxFlex="100">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Modifier la catégorie
                  </mat-panel-title>
                </mat-expansion-panel-header>
  
                <div fxLayout="row">
                  <mat-form-field fxFlex="100">
                    <mat-label>Titre</mat-label>
                    <input matInput type="text" [ngModel]="categorie.titre" (ngModelChange)="categorie.titre=$event">
                  </mat-form-field>
                </div>
                <div fxLayout="row">
                  <mat-form-field fxFlex="100">
                    <mat-label>Description</mat-label>
                    <textarea matInput type="text" [ngModel]="categorie.description" (ngModelChange)="categorie.description=$event"></textarea>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <div fxFlex="30" fxLayoutAlign="center center">
                    Modifications de la consultation :
                  </div>
                  <div fxFlex="35" fxLayoutAlign="center center">
                    <button mat-raised-button color="warn" (click)="getCategories()">Annuler</button>
                  </div>
                  <div fxFlex="35" fxLayoutAlign="center center">
                    <button mat-raised-button color="primary" (click)="saveCategorie(categorie)">Enregistrer</button>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="100" fxLayoutAlign="center center">
            &nbsp;
          </div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="100" fxLayoutAlign="center center">
            &nbsp;
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="50">
            <strong>Pronostics de la catégorie :</strong>
          </div>
          <div fxFlex="25">
            <button mat-raised-button color="primary" aria-label="Créer un pronostic" (click)="openDialogParis(categorie.id)">
              <mat-icon>add</mat-icon> Pronostic
            </button>
          </div>
          <div fxFlex="25">
            <button mat-raised-button color="primary" aria-label="Créer une journée" (click)="openDialogJournee(categorie.id)">
              <mat-icon>add</mat-icon> Journée
            </button>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="100" fxLayoutAlign="center center">
            &nbsp;
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="100" fxLayoutAlign="center center">
            <mat-accordion class="example-headers-align" multi fxLayout="column" fxFlex="100">
              <mat-expansion-panel fxLayout="column" fxFlex="100" *ngFor="let pari of categorie.paris">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ pari.titre }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <span *ngIf="pari.status === 'inactive'"><mat-icon>design_services</mat-icon> Inactif</span>
                    <span *ngIf="pari.status === 'active'"><mat-icon>play_circle</mat-icon> Actif</span>
                    <span *ngIf="pari.status === 'waiting'"><mat-icon>hourglass_top</mat-icon> En attente</span>
                    <span *ngIf="pari.status === 'ended'"><mat-icon>verified</mat-icon> Terminé</span>
                    <span *ngIf="pari.status === 'canceled'"><mat-icon>cancel</mat-icon> Annulé</span>
                    <button *ngIf="pari.status !== 'ended' && pari.status !== 'active'" mat-raised-button color="primary" (click)="nextStatusAndSavePari(pari)">Changer statut</button>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="100" fxLayoutAlign="center center">
                      <mat-accordion class="example-headers-align" multi fxLayout="column" fxFlex="100">
                        <mat-expansion-panel fxLayout="column" fxFlex="100">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Modifier le pronostic
                            </mat-panel-title>
                          </mat-expansion-panel-header>
            
                          <div fxLayout="row">
                            <mat-form-field fxFlex="100">
                              <mat-label>Titre</mat-label>
                              <input matInput type="text" [ngModel]="pari.titre" (ngModelChange)="pari.titre=$event">
                            </mat-form-field>
                          </div>
                          <div fxLayout="row">
                            <mat-form-field fxFlex="100">
                              <mat-label>Description</mat-label>
                              <textarea matInput type="text" [ngModel]="pari.description" (ngModelChange)="pari.description=$event"></textarea>
                            </mat-form-field>
                          </div>
                          <div flex="" fxLayout="row" fxLayoutAlign="left">
                            <mat-form-field fxFlex="100">
                                <mat-label>Date de fin</mat-label>
                                <input matInput [matDatepicker]="pickerFin" name="date_fin" [ngModel]="pari.date_fin" (ngModelChange)="changeDateFin(pari, $event)">
                                <mat-hint>JJ/MM/AAAA</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="pickerFin"></mat-datepicker-toggle>
                                <mat-datepicker #pickerFin></mat-datepicker>
                            </mat-form-field>
                          </div>
                          
                          <div fxLayout="row">
                            <mat-form-field fxFlex="100">
                              <mat-label>Heure de fin</mat-label>
                              <input matInput type="number" min="1" max="23" [ngModel]="getHourFin(pari)" (ngModelChange)="changeHourFin(pari, $event)">
                            </mat-form-field>
                          </div>
            
                          <div fxLayout="row">
                            <mat-form-field fxFlex="100">
                                <mat-label>Statut du pronostic</mat-label>
                                <mat-select [ngModel]="pari.status" name="status" (ngModelChange)="changeStatus(pari, $event)">
                                    <mat-option value="inactive">Brouillon <mat-icon>design_services</mat-icon></mat-option>
                                    <mat-option value="active">Actif <mat-icon>play_circle</mat-icon></mat-option>
                                    <mat-option value="waiting">En attente <mat-icon>hourglass_top</mat-icon></mat-option>
                                    <mat-option value="ended">Terminé <mat-icon>verified</mat-icon></mat-option>
                                    <mat-option value="canceled">Annulé <mat-icon>cancel</mat-icon></mat-option>
                                </mat-select>
                            </mat-form-field>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="center center">
                            <div fxFlex="30" fxLayoutAlign="center center">
                              Modifications du pronostic :
                            </div>
                            <div fxFlex="35" fxLayoutAlign="center center">
                              <button mat-raised-button color="warn" (click)="getParis(categorie.id)">Annuler</button>
                            </div>
                            <div fxFlex="35" fxLayoutAlign="center center">
                              <button mat-raised-button color="primary" (click)="savePari(pari)">Enregistrer</button>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="100" fxLayoutAlign="center center">
                      &nbsp;
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="100" fxLayoutAlign="center center">
                      &nbsp;
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="50">
                      <strong>Options du pronostic :</strong>
                    </div>
                    <div fxFlex="50">
                      <button mat-raised-button color="primary" aria-label="Créer un pronostic" (click)="openDialogOption(categorie.id, pari)">
                        <mat-icon>add</mat-icon> Ajouter une option
                      </button>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="100" fxLayoutAlign="center center">
                      &nbsp;
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="100" fxLayoutAlign="center center">
                      <mat-accordion class="example-headers-align" multi fxLayout="column" fxFlex="100">
                        <mat-expansion-panel fxLayout="column" fxFlex="100" *ngFor="let option of pari.options">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                {{ option.titre }}
                                </mat-panel-title>
                                <mat-panel-description>
                                  <button *ngIf="!option.isValidated" mat-raised-button color="primary" (click)="switchAndSaveOption(categorie, pari, option)">Passer gagnant</button>
                                  <button *ngIf="option.isValidated" mat-raised-button color="warn" (click)="switchAndSaveOption(categorie, pari, option)">Passer non gagnant</button>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div fxLayout="row">
                                <mat-form-field fxFlex="100">
                                    <mat-label>Titre</mat-label>
                                    <input matInput type="text" [ngModel]="option.titre" (ngModelChange)="option.titre=$event">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row">
                                <mat-form-field fxFlex="100">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput type="text" [ngModel]="option.description" (ngModelChange)="option.description=$event"></textarea>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row">
                                <mat-form-field fxFlex="100">
                                    <mat-label>Score si valide</mat-label>
                                    <textarea matInput type="number" [ngModel]="option.score" (ngModelChange)="option.score=$event"></textarea>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row">
                                <div fxFlex="100">
                                    Gagnant? <mat-slide-toggle [(ngModel)]="option.isValidated"><span *ngIf="option.isValidated">Gagnant</span><span *ngIf="!option.isValidated">Perdant</span></mat-slide-toggle>
                                </div>
                            </div>
                            <div fxLayout="row">
                                <div fxFlex="100">&nbsp;</div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div fxFlex="30" fxLayoutAlign="center center">
                                Modifications de l'option :
                                </div>
                                <div fxFlex="35" fxLayoutAlign="center center">
                                    <button mat-raised-button color="warn" (click)="getOptions(pari.categorie.id, pari.id)">Annuler</button>
                                </div>
                                <div fxFlex="35" fxLayoutAlign="center center">
                                    <button mat-raised-button color="primary" (click)="saveOption(categorie, pari, option)">Enregistrer</button>
                                </div>
                            </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  