<div class="dialog" fxLayout="column">
    <h1 mat-dialog-title flex="100">Création d'un pronostic</h1>
    <div mat-dialog-content fxLayout="column">
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Titre*</mat-label>
            <input matInput type="text" [ngModel]="data.pari.titre" (ngModelChange)="data.pari.titre=$event">
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Description*</mat-label>
            <textarea matInput type="text" [ngModel]="data.pari.description" (ngModelChange)="data.pari.description=$event"></textarea>
          </mat-form-field>
        </div>
        <div flex="" fxLayout="row" fxLayoutAlign="left">
          <mat-form-field fxFlex="100">
              <mat-label>Date de fin*</mat-label>
              <input matInput [matDatepicker]="pickerFin" name="date_fin" [ngModel]="data.pari.date_fin" (ngModelChange)="data.parent.changeDateFin(data.pari, $event)">
              <mat-hint>JJ/MM/AAAA</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="pickerFin"></mat-datepicker-toggle>
              <mat-datepicker #pickerFin></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Heure de fin*</mat-label>
            <input matInput type="number" min="1" max="23" [ngModel]="data.parent.getHourFin(data.pari)" (ngModelChange)="data.parent.changeHourFin(data.pari, $event)">
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="50" fxLayoutAlign="center center">
            <button mat-raised-button color="warn" (click)="onNoClick()">Annuler</button>
          </div>
          <div fxFlex="50" fxLayoutAlign="center center">
            <button mat-raised-button color="primary" [mat-dialog-close]="data.pari">Enregistrer</button>
          </div>
        </div>
    </div>
</div>