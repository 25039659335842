import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConsultationEdit } from '../cons/cons.dto';
import { ConnectorService } from '../connector.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent {
  totalPoints:number = 0;
  openedStat: any[] = [];
  listConsultations: ConsultationEdit[] = [];
  currentConsultation:number = 0;

  constructor(
    private http: HttpClient,
    private connector: ConnectorService
  ) {}

  isRGPD(){
    return this.connector.isAdminRgpd();
  }

  async ngOnInit() {
    if(!this.connector.isConnected()){
      this.connector.disconnection();
    }
    await this.getConsultations();
  }

  getStatus(value:string){
    if(value === 'ended'){
      return 'Terminé';
    }
    return 'En cours';
  }

  consHasVolontaires():boolean{
    const currCons:ConsultationEdit|undefined = this.getCurrentConsultation();
    if(currCons !== undefined && currCons.questions !== undefined){
      let hasVolontaires = false;
      for(let i = 0; i < currCons.questions.length; i++){
        hasVolontaires = hasVolontaires || currCons.questions[i].volontariat;
      }
      return hasVolontaires;
    }
    return false;
  }

  getCurrentConsultation():ConsultationEdit|undefined{
    const _this = this;
    return this.listConsultations.find((element) => element.id === _this.currentConsultation);
  }

  updateValeurs(){
    const newList:{id:number, ordre:number, type:string, titre:string, oui:number, non:number, abs:number, total:number}[] = [];
    for(let element of this.openedStat){
      let line = newList.find((found) => found.id === element.id);
      if(line === undefined){
        line = { id: element.id, ordre: element.ordre, type: "valeurs", titre: element.titre, oui: 0, non: 0, abs: 0, total: 0};
        newList.push(line);
      }
      if(element.resultat === "OUI"){
        line.oui = Number(element.quantite);
        line.total = line.total + line.oui;
      }
      if(element.resultat === "NON"){
        line.non = Number(element.quantite);
        line.total = line.total + line.non;
      }
      if(element.resultat === "ABS"){
        line.abs = Number(element.quantite);
        line.total = line.total + line.abs;
      }
    }
    this.openedStat = newList.sort(function(a:any, b:any){
      if(Number(a.ordre) < Number(b.ordre)) { return -1; }
      if(Number(a.ordre) > Number(b.ordre)) { return 1; }
      return 0;
    });
  }

  getPourcentageValeurs(value:number, total:number){
    return Math.round((value*10000)/total)/100;
  }

  updateTotalPoints(){
    this.totalPoints = 0;
    for(let element of this.openedStat){
      this.totalPoints += Number(element.somme);
    }
  }

  getPourcentage(points:number){
    return Math.round((points*10000)/this.totalPoints)/100;
  }

  getAverage(stat:any){
    return Math.round((stat.somme/stat.quantite)*100)/100;
  }

  async getConsultations(){
    const _this = this;
    this.openedStat = [];
    await this.http.get<ConsultationEdit[]>(environment.backAPI+'/consultations/admin/stats/consultations',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          _this.listConsultations = result;
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }

  async changeConsultation(value:any){
    const _this = this;
    this.currentConsultation = Number(value.target.value);
    await this.http.get<ConsultationEdit[]>(environment.backAPI+'/consultations/admin/stats/'+value.target.value,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          _this.openedStat = result.sort(function(a:any, b:any){
            if(a.type === 'credits'){
              if(Number(a.somme) < Number(b.somme)) { return 1; }
              if(Number(a.somme) > Number(b.somme)) { return -1; }
            }else if(a.type === 'valeurs'){
              if(Number(a.ordre) < Number(b.ordre)) { return -1; }
              if(Number(a.ordre) > Number(b.ordre)) { return 1; }
            }
            return 0;
          });
          if(_this.openedStat.length > 0){
            if(_this.openedStat[0].type === 'credits'){
              _this.updateTotalPoints();
            }
            if(_this.openedStat[0].type === 'valeurs'){
              _this.updateValeurs();
            }
          }
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }
  async fileDownload(id:number){
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    let file = environment.backAPI+'/consultations/admin/volontaires/'+id;

    let headers = new Headers();
    headers.append('Authorization', 'Bearer '+this.connector.token());

    fetch(file, { headers })
        .then(response => response.blob())
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);

            anchor.href = objectUrl;
            anchor.download = "Volontaires_"+id+".xlsx";
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
    });
  }
}
