import { Component, Inject } from '@angular/core';
import { HttpClient, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ConnectorService } from '../connector.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ConsultationCreate, ConsultationEdit, ConsultationStatus, ConsultationTarget, ConsultationType, QuestionCreate, QuestionEdit } from './cons.dto';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { NgIf } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { AngularEditorModule } from '@kolkov/angular-editor';

@Component({
  selector: 'app-cons',
  templateUrl: './cons.component.html',
  styleUrls: ['./cons.component.scss']
})
export class ConsComponent {
  openedConsultation: ConsultationEdit|null = null;
  listConsultationsObs: Observable<ConsultationEdit[]> = new Observable();
  listConsultations: ConsultationEdit[] = [];
  toBeCreatedCons: ConsultationCreate = new ConsultationCreate();

  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    public dialog: MatDialog,
    private _adapter: DateAdapter<any>
  ) {}

  async ngOnInit() {
    if(!this.connector.isConnected()){
      this.connector.disconnection();
    }
    this._locale = 'fr';
    this._adapter.setLocale(this._locale);
    await this.getConsultations();
  }

  getById(id:number):ConsultationEdit|undefined{
    return this.listConsultations.find(c => c.id == id);
  }

  changeTitre(id:number, value:string){
    this.getById(id)!.titre = value;
  }

  changeDescription(id:number, value:string){
    this.getById(id)!.description = value;
  }

  changeDateDebut(id:number, value:Date){
    const myItem = this.checkHourDebut(id);
    value.setHours(myItem!.date_debut.getHours());
    value.setMinutes(myItem!.date_debut.getMinutes());
    myItem!.date_debut = value;
  }

  changeHourDebut(id:number, value:number){
    const myItem = this.checkHourDebut(id);
    myItem!.date_debut.setHours(value);
    myItem!.date_debut.setMinutes(0);
  }

  changeDateFin(id:number, value:any){
    const myItem = this.checkHourFin(id);
    value.setHours(myItem!.date_fin.getHours());
    value.setMinutes(myItem!.date_fin.getMinutes());
    myItem!.date_fin = value;
  }

  changeHourFin(id:number, value:number){
    const myItem = this.checkHourFin(id);
    myItem!.date_fin.setHours(value);
    myItem!.date_fin.setMinutes(0);
  }

  getHourDebut(id:number){
    const myItem = this.checkHourDebut(id);
    return myItem!.date_debut.getHours();
  }

  getHourFin(id:number){
    const myItem = this.checkHourFin(id);
    return myItem!.date_fin.getHours();
  }

  checkHourDebut(id:number){
    const myItem = this.getById(id);
    if(!(myItem!.date_debut instanceof Date)){
      myItem!.date_debut = new Date(myItem!.date_debut);
    }
    return myItem;
  }

  checkHourFin(id:number){
    const myItem = this.getById(id);
    if(!(myItem!.date_fin instanceof Date)){
      myItem!.date_fin = new Date(myItem!.date_fin);
    }
    return myItem;
  }

  changeStatus(id:number, value:string){
    let newValue:ConsultationStatus = ConsultationStatus.DRAFT;
    if(value === ConsultationStatus.ACTIVE.toString()){
      newValue = ConsultationStatus.ACTIVE;
    }
    if(value === ConsultationStatus.CANCELED.toString()){
      newValue = ConsultationStatus.CANCELED;
    }
    if(value === ConsultationStatus.ENDED.toString()){
      newValue = ConsultationStatus.ENDED;
    }
    this.getById(id)!.status = newValue;
  }

  changeCredits(id:number, value:number){
    this.getById(id)!.credits = value;
  }

  changeType(id:number, value:string){
    if(value === 'credits'){
      this.getById(id)!.type = ConsultationType.CREDITS;
    }else{
      this.getById(id)!.type = ConsultationType.VALEURS;
    }
  }

  changeTarget(id:number, value:string){
    if(value === 'all'){
      this.getById(id)!.target = ConsultationTarget.ALL;
    }
    if(value === 'socios'){
      this.getById(id)!.target = ConsultationTarget.SOCIOS;
    }
    if(value === 'adherents'){
      this.getById(id)!.target = ConsultationTarget.ADHERENTS;
    }
  }

  changeTitreQuestion(question: QuestionEdit, event:string){
    question.titre = event;
  }

  changeDescriptionQuestion(question: QuestionEdit, event:string){
    question.description = event;
  }

  changeLienQuestion(question: QuestionEdit, event:string){
    question.lien = event;
  }

  changeObligatoire(question:QuestionEdit, $event:boolean){
    question.obligatoire = $event;
  }

  changeVolontariat(question:QuestionEdit, $event:boolean){
    question.volontariat = $event;
  }

  isCredits(id:number): boolean{
    return this.getById(id)!.type === ConsultationType.CREDITS;
  }

  getSortedQuestions(questions:QuestionEdit[]|undefined){
    if(questions === undefined){
      return [];
    }
    return questions.sort(function(a, b) {
      return a.ordre - b.ordre;
    });
  }

  saveQuestion(consId:number, questionId:number){
    const cons:ConsultationEdit|undefined = this.getById(consId);
    if(cons === undefined){
      alert("Consultation introuvable");
      return;
    }
    const question:QuestionEdit|undefined = cons!.questions!.find(q => q.id === questionId);
    this.http.post<QuestionEdit>(environment.backAPI+'/consultations/admin/question', question, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          this.getConsultations();
        }
        else{
          this.connector.disconnection();
        }
      },
      (error) => {
        this.connector.disconnection();
      }
    );
  }

  saveConsultation(id:number){
    const cons:ConsultationEdit|undefined = this.getById(id);
    if(cons !== undefined && cons.type !== ConsultationType.CREDITS){
      cons.credits = 0;
    }
    this.http.post<ConsultationEdit>(environment.backAPI+'/consultations/admin/consultation', cons, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          this.getConsultations();
        }
        else{
          this.connector.disconnection();
        }
      },
      (error) => {
        this.connector.disconnection();
      }
    );
  }

  async getConsultations(){
    const _this = this;
    this.openedConsultation = null;
    this.listConsultationsObs = await this.http.get<ConsultationEdit[]>(environment.backAPI+'/consultations/admin/consultation',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    });
    this.listConsultationsObs.subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          _this.listConsultations = result;
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }
  
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogConsCreateDialog, {
      data: {cons: this.toBeCreatedCons, parent: this},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined && result instanceof ConsultationCreate){
        this.http.put<ConsultationEdit>(environment.backAPI+'/consultations/admin/consultation', result, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ this.connector.token()
          }
        }).subscribe(
          (result) => {
            if(result !== undefined && !(result instanceof HttpErrorResponse)){
              this.getConsultations();
            }
            else{
              this.connector.disconnection();
            }
          },
          (error) => {
            this.connector.disconnection();
          }
        );
      }
    });
  }

  openDialogQuestion(consId:number){
    const dialogRef = this.dialog.open(DialogQuestionCreateDialog, {
      data: {question: new QuestionCreate(), parent: this},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined && result instanceof QuestionCreate){
        const cons:ConsultationEdit|undefined = this.getById(consId);
        result.ordre = (cons?.questions === undefined)? 0 : cons?.questions.length;
        this.http.put<QuestionEdit>(environment.backAPI+'/consultations/admin/question', {consultationId : consId, question :result}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ this.connector.token()
          }
        }).subscribe(
          (result) => {
            if(result !== undefined && !(result instanceof HttpErrorResponse)){
              this.getConsultations();
            }
            else{
              this.connector.disconnection();
            }
          },
          (error) => {
            this.connector.disconnection();
          }
        );
      }
    });
  }
}

@Component({
  selector: 'create-question-dialog',
  templateUrl: 'create-question-dialog.html',
  styleUrls: ['./create-cons-dialog.css'],
  standalone: true,
  imports: [HttpClientModule,AngularEditorModule,MatSlideToggleModule,MatDatepickerModule,NgxMatTimepickerModule,NgxMatDatetimePickerModule,MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule, FormsModule, MatButtonModule, MatCardModule, MatIconModule, FlexLayoutModule, NgIf],
})
export class DialogQuestionCreateDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogQuestionCreateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { question: QuestionCreate, parent: ConsComponent},
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  changeTitre(value:string){
    this.data.question.titre = value;
  }
  
  changeDescription(value:string){
    this.data.question.description = value;
  }
  
  changeObligatoire(value:boolean){
    this.data.question.obligatoire = value;
  }
  
  changeVolontariat(value:boolean){
    this.data.question.volontariat = value;
  }
  
  changeLien(value:string){
    this.data.question.lien = value;
  }
}

@Component({
  selector: 'create-cons-dialog',
  templateUrl: 'create-cons-dialog.html',
  styleUrls: ['./create-cons-dialog.css'],
  standalone: true,
  imports: [HttpClientModule,AngularEditorModule,MatDatepickerModule,NgxMatTimepickerModule,NgxMatDatetimePickerModule,MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule, FormsModule, MatButtonModule, MatCardModule, MatIconModule, FlexLayoutModule, NgIf],
})
export class DialogConsCreateDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogConsCreateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { cons: ConsultationCreate, parent: ConsComponent},
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  getHourDebut(){
    return this.data.cons.date_debut.getHours();
  }

  getHourFin(){
    return this.data.cons.date_fin.getHours();
  }
  
  changeDateDebut(value:Date){
    const myItem = this.checkHourDebut();
    value.setHours(myItem!.date_debut.getHours());
    value.setMinutes(myItem!.date_debut.getMinutes());
    myItem!.date_debut = value;
  }

  changeHourDebut(value:number){
    const myItem = this.checkHourDebut();
    myItem!.date_debut.setHours(value);
    myItem!.date_debut.setMinutes(0);
  }

  changeDateFin(value:any){
    const myItem = this.checkHourFin();
    value.setHours(myItem!.date_fin.getHours());
    value.setMinutes(myItem!.date_fin.getMinutes());
    myItem!.date_fin = value;
  }

  changeHourFin(value:number){
    const myItem = this.checkHourFin();
    myItem!.date_fin.setHours(value);
    myItem!.date_fin.setMinutes(0);
  }

  checkHourDebut(){
    const myItem = this.data.cons;
    if(!(myItem!.date_debut instanceof Date)){
      myItem!.date_debut = new Date(myItem!.date_debut);
    }
    return myItem;
  }

  checkHourFin(){
    const myItem = this.data.cons;
    if(!(myItem!.date_fin instanceof Date)){
      myItem!.date_fin = new Date(myItem!.date_fin);
    }
    return myItem;
  }

  changeCredits(value:number){
    this.data.cons.credits = value;
  }

  changeType(value:string){
    if(value === 'credits'){
      this.data.cons.type = ConsultationType.CREDITS;
    }else{
      this.data.cons.type = ConsultationType.VALEURS;
      this.data.cons.credits = 0;
    }
  }

  changeTarget(value:string){
    if(value === 'all'){
      this.data.cons.target = ConsultationTarget.ALL;
    }
    if(value === 'socios'){
      this.data.cons.target = ConsultationTarget.SOCIOS;
    }
    if(value === 'adherents'){
      this.data.cons.target = ConsultationTarget.ADHERENTS;
    }
  }

  changeTitre(value:string){
    this.data.cons.titre = value;
  }

  changeDescription(value:string){
    this.data.cons.description = value;
  }
}
