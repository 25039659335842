  export enum DocumentStatus {
    DRAFT = "draft",
    ACTIVE = "active",
    INACTIVE = "inactive"
  }

  export enum DocumentTarget {
    SOCIOS = 'socios',
    ADHERENTS = 'adherents',
    ALL = 'all',
  }
  
  export class DocumentDto {
    id:number = 0;
    name: string = "";
    description: string = "";
    created: Date = new Date();
    update: Date = new Date();
    type: string = "";
    filename: string = "";
    size: number = 0;
    status: DocumentStatus = DocumentStatus.DRAFT;
    target: DocumentTarget = DocumentTarget.ALL;
  }

  export class CreateDoc {
    id:number = 0;
    name?:string;
    description?: string;
    status?: DocumentStatus;
    target?: DocumentTarget;
    file?: File;
  }