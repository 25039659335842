<div class="dialog" fxLayout="column">
    <h1 mat-dialog-title flex="100" *ngIf="data.document.id === 0">Création d'un document</h1>
    <h1 mat-dialog-title flex="100" *ngIf="data.document.id !== 0">Mise à jour d'un document</h1>
    <div mat-dialog-content fxLayout="column">
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Nom*</mat-label>
            <input matInput type="text" [ngModel]="data.document.name" (ngModelChange)="changeName($event)">
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>Description*</mat-label>
            <textarea matInput type="text" [ngModel]="data.document.description" (ngModelChange)="changeDescription($event)"></textarea>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <div fxFlex="100">
            <label for="file">Fichier*</label>
            <input id="file" type="file" (change)="changeFile($event)" #fileUpload>
          </div>
        </div>
        
        <div fxLayout="row">
          <div fxFlex="100">&nbsp;</div>
        </div>
  
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
              <mat-label>Visibilité du document*</mat-label>
              <mat-select [ngModel]="data.document.status" (ngModelChange)="changeStatus($event)">
                <mat-option value="draft">Brouillon <mat-icon>design_services</mat-icon></mat-option>
                <mat-option value="active">Publié <mat-icon>play_circle</mat-icon></mat-option>
                <mat-option value="inactive">Désactivé <mat-icon>cancel</mat-icon></mat-option>
              </mat-select>
          </mat-form-field>
        </div>
  
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
              <mat-label>Public cible*</mat-label>
              <mat-select [ngModel]="data.document.target" (ngModelChange)="changeTarget($event)">
                <mat-option value="all">Tous</mat-option>
                <mat-option value="socios">Socios seulement</mat-option>
                <mat-option value="adherents">Adhérents seulement</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="50" fxLayoutAlign="center center">
            <button mat-raised-button color="warn" (click)="onNoClick()">Annuler</button>
          </div>
          <div fxFlex="50" fxLayoutAlign="center center">
            <button mat-raised-button color="primary" [mat-dialog-close]="data.document">Enregistrer</button>
          </div>
        </div>
    </div>
</div>