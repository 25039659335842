
export enum ConsultationStatus {
    DRAFT = "draft",
    ACTIVE = "active",
    ENDED = "ended",
    CANCELED = "canceled"
}

export enum ConsultationTarget {
    SOCIOS = 'socios',
    ADHERENTS = 'adherents',
    ALL = 'all',
}

export enum ConsultationType {
    CREDITS = "credits",
    VALEURS = "valeurs"
}

export class ConsultationCreate {
    titre: string = "";
    description: string = "";
    date_debut: Date = new Date();
    date_fin: Date = new Date();
    type: ConsultationType = ConsultationType.CREDITS;
    credits: number = 0;
    target: ConsultationTarget = ConsultationTarget.ALL;
}

export class ConsultationEdit extends ConsultationCreate {
    id:number = 0;
    status:ConsultationStatus = ConsultationStatus.DRAFT;
    date_start_day:string = "";
    date_start_hour:string = "";
    date_end_day:string = "";
    date_end_hour:string = "";
    questions?: QuestionEdit[] = [];
}

export class QuestionCreate {
    titre: string = "";
    description: string = "";
    obligatoire: boolean = false;
    volontariat: boolean = false;
    lien: string = "";
    ordre:number = 0;
}

export class QuestionEdit extends QuestionCreate{
    id: number = 0;
}