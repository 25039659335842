import { Component, Inject } from '@angular/core';
import { Account, Socio } from './account.dto';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ConnectorService } from '../connector.service';
import { DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import { COUNTRIES } from './country-data-store';
import { Observable } from 'rxjs';


export interface DialogData {
  password: string;
  title: string;
  display: boolean;
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  ],
})
export class AccountComponent {
  public countries:any = COUNTRIES;
  account: Account|null = null;
  accountSearch: Observable<Account[]> = new Observable();
  search:string = "";
  
  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private _adapter: DateAdapter<any>
  ) {}

  ngOnInit(): void {
    if(!this.connector.isConnected()){
      this.connector.disconnection();
    }
    this._locale = 'fr';
    this._adapter.setLocale(this._locale);
  }

  setAccount(account:Account){
    this.account = account;
    this.accountSearch = new Observable();
  }

  matchID(account:Account):Socio|null{
    if(Number(this.search)+"" === this.search){
      const id:number = Number(this.search);
      const foundSocio:Socio|undefined = account.socios.find((socio) => socio.techId === id);
      if(foundSocio){
        return foundSocio;
      }
    }
    return null;
  }

  matchEmail(account:Account){
    const foundSocio:Socio|undefined = account.socios.find((socio) => socio.mail.includes(this.search));
    if(foundSocio){
      return foundSocio;
    }
    return null;
  }
  
  matchName(account:Account){
    const foundSocio:Socio|undefined = account.socios.find((socio) => socio.nom.includes(this.search));
    if(foundSocio){
      return foundSocio;
    }
    return null;
  }

  matchGivenname(account:Account){
    const foundSocio:Socio|undefined = account.socios.find((socio) => socio.prenom.includes(this.search));
    if(foundSocio){
      return foundSocio;
    }
    return null;
  }

  displaySearch(account:Account){
    const matchEmail = this.matchEmail(account);
    const matchID = this.matchID(account);
    const matchName = this.matchName(account);
    const matchGivenname = this.matchGivenname(account);
    if(matchID !== null){
      return "Socio: "+matchID.techId+" | Nom: "+matchID.nom+" | Prénom: "+matchID.prenom;
    }
    if(matchName !== null){
      return "Nom: "+matchName.nom+" | Prénom: "+matchName.prenom;
    }
    if(matchGivenname !== null){
      return "Prénom: "+matchGivenname.prenom+" | Nom: "+matchGivenname.nom;
    }
    if(matchEmail !== null){
      return matchEmail.mail;
    }
    return account.email;
  }

  reinitPwd(){
    alert('reinit du mdp');
  }

  async getAccounts(){
    if(this.search !== undefined && this.search !== null && this.search.length > 0){
      this.account = null;
      const valueSearched:string|number = (Number(this.search)+"" === this.search)?Number(this.search):this.search;
      this.accountSearch = this.http.post<Account[]>(environment.backAPI+'/compte/admin/search',{search: valueSearched},{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ this.connector.token()
        }
      });
      this.accountSearch.subscribe(
        (result) => {
          if(result !== undefined && !(result instanceof HttpErrorResponse)){
            return result;
          }
          else{
            this.connector.disconnection();
            return [];
          }
        },
        (error) => {
          this.connector.disconnection();
          return [];
        }
      );
    }
  }

  editMode(id:number, value:boolean){
    const idToSelect:string = "#socio-"+id;
    const el:Element|null = document.body.querySelector(idToSelect);
    if(el !== null){
      el.setAttribute("isEditing",(value)?"1":"0");
    }
  }

  getFieldValue(parentId:string, name:string){
    let el:HTMLInputElement|null = document.body.querySelector(parentId+" input[name="+name+"]");
    if(el !== null){
      return el.value;
    }
    el = document.body.querySelector(parentId+" mat-select[name="+name+"]");
    if(el !== null){
      return el.getAttribute('ng-reflect-value');
    }
    return null;
  }

  async validate(id:number){
    const idToSelect:string = "#socio-"+id;
    const _this = this;
    
    await this.http.post<Account>(environment.backAPI+'/socio', {
      id: id,
      nom: this.getFieldValue(idToSelect,"nom"),
      prenom:this.getFieldValue(idToSelect,"prenom"),
      date_naissance:this.getFieldValue(idToSelect,"date_naissance")?.replaceAll("/","-"),
      adresse:this.getFieldValue(idToSelect,"adresse"),
      code_postal:this.getFieldValue(idToSelect,"code_postal"),
      ville:this.getFieldValue(idToSelect,"ville"),
      pays:this.getFieldValue(idToSelect,"pays")
    },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          this.editMode(id, false);
          let socio:Socio|undefined = this.account?.socios.filter(socio => socio.id === id)[0];
          if(socio !== undefined){
            socio.nom = _this.notNull(this.getFieldValue(idToSelect,"nom"));
            socio.prenom = _this.notNull(this.getFieldValue(idToSelect,"prenom"));
            socio.date_naissance = new Date(_this.notNull(this.getFieldValue(idToSelect,"date_naissance")?.replaceAll("/","-")));
            socio.adresse = _this.notNull(this.getFieldValue(idToSelect,"adresse"));
            socio.code_postal = _this.notNull(this.getFieldValue(idToSelect,"code_postal"));
            socio.ville = _this.notNull(this.getFieldValue(idToSelect,"ville"));
            socio.pays = _this.notNull(this.getFieldValue(idToSelect,"pays"));
          }
        }
        else{
          this.connector.disconnection();
        }
      },
      (error) => {
        this.connector.disconnection();
      }
    );
  }

  notNull(value:string|null|undefined){
    if(value === undefined || value === null){
      return "";
    }
    return value;
  }

  capitalizeFirstLetter(str:string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
}
