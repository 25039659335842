import { Component } from '@angular/core';
import packageJson from '../../package.json';
import { ConnectorService } from './connector.service';

@Component({
  selector: 'app-sociochaux',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Sociochaux : Espaces Admin';
  public version: string = packageJson.version;

  constructor(private connector: ConnectorService){}

  getYear(){
    return (new Date()).getFullYear();
  }

  isConnected(){
    return this.connector.isConnected();
  }
}
