<nav class="sectionNavbar">
    <mat-toolbar color="primary" class="navbar">
        <span class="logoText">Admin</span><img src="https://sociochaux.fr/wp-content/uploads/2023/07/logo-sociochaux@3x-150x134.png" class="custom-logo" alt="Logo Sociochaux, un lion rugissant vers l’avenir, car Sochaux vivra" decoding="async" srcset="https://sociochaux.fr/wp-content/uploads/2023/07/logo-sociochaux@3x-150x134.png 150w, https://sociochaux.fr/wp-content/uploads/2023/07/logo-sociochaux@3x-300x269.png 300w, https://sociochaux.fr/wp-content/uploads/2023/07/logo-sociochaux@3x-1024x917.png 1024w, https://sociochaux.fr/wp-content/uploads/2023/07/logo-sociochaux@3x-768x688.png 768w, https://sociochaux.fr/wp-content/uploads/2023/07/logo-sociochaux@3x-1536x1376.png 1536w, https://sociochaux.fr/wp-content/uploads/2023/07/logo-sociochaux@3x.png 1563w" class="logo">
        <span class="example-spacer"></span>
        <button mat-icon-button fxHide.gt-sm *ngIf="isConnected" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <button mat-button fxHide.lt-md *ngIf="isConnected" [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon> MENU
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let menu of menuToDisplay" [routerLink]="[menu.link]">
                <mat-icon>{{menu.icon}}</mat-icon>
                <span>{{menu.title}}</span>
            </button>
            <button mat-menu-item (click)="disconnect()">
                <mat-icon>logout</mat-icon>
                <span>Déconnexion</span>
            </button>
        </mat-menu>
    </mat-toolbar>
</nav>