<div class="dialog">
    <h1 mat-dialog-title flex="100">Réinitialisation du mot de passe</h1>
    <div mat-dialog-content flex="100">
    <p flex="100">Veuillez saisir votre adresse email et votre code reçu dans votre email de création de compte</p>
    <mat-form-field fxLayout="row">
        <mat-label>Email du compte</mat-label>
        <input matInput type="email" [(ngModel)]="data.email">
    </mat-form-field>
    <mat-form-field fxLayout="row">
        <mat-label>Code personnel</mat-label>
        <input matInput type="password" [(ngModel)]="data.code">
    </mat-form-field>
    </div>
    <div mat-dialog-actions align="center">
        <button mat-raised-button (click)="onNoClick()" color="warn">Annuler</button>
        <button mat-raised-button [mat-dialog-close]="{ email: data.email, code: data.code }" color="primary" cdkFocusInitial>Valider</button>
    </div>
</div>